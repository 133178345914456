export class UserSession {

  public accessToken: string;
  public productId: string;
  public tenantId: string;
  public userId: string;
  public userName: string;
  public powerUser: boolean;
  public adminUser: boolean;
  public displayName: string;
  public issuer: string;
  public constructor() {
    this.accessToken = "";
    this.productId = "";
    this.tenantId = "";
    this.userId = "";
    this.userName = "";
    this.powerUser = false;
    this.adminUser = false;
    this.displayName = "";
    this.issuer = "";
  }

}
