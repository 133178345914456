import { LogService } from './../../shared/log/log.service';
import { LocalStorageService } from './../../shared/storage/local-storage.service';
import { UtilService } from './../../shared/util/util.service';
import { Injectable } from '@angular/core';
import { CognitoJwtVerifier } from 'aws-jwt-verify';
import { environment } from 'src/environments/environment';
import { CognitoJwt } from './cognitoJwt.model';
import { UserSession } from './user-session.model';
import jwtDecode, { JwtPayload } from 'jwt-decode';

@Injectable({
  providedIn: 'root',
})
export class AuthService {


  private _localStorageUserSessionItemName = 'rab-user-session';

  private _currentAuth: UserSession = new UserSession();

  constructor(private _utilService: UtilService, private _localStorageService: LocalStorageService, private _logService: LogService) {

  }

  public validateAccessToken = (product: string, tenant: string, token: string, issuer: string): Promise<CognitoJwt> => {

    // Pass the token that the Cognito API has given to you (either id or access one)
    var isValid = false;
    var appClientId = "";
    var jwt = new CognitoJwt();
    if (product == "mc_trade") {

      if (this._utilService.containsAnyCase(issuer, "cognito")) {
        appClientId = environment.cognitoAppIdMcTrade;

        // Verifier that expects valid access tokens:
        var verifier = CognitoJwtVerifier.create({
          userPoolId: environment.cognitoUserPoolId,
          tokenUse: "access",
          clientId: appClientId,
        });


        return verifier.verify(
          token // the JWT as string
        ).then((payload: any) => {
          this._logService.logObject("Token is valid. Payload:", payload);
          var jwt = <CognitoJwt>payload;
          jwt.isValid = true;
          return jwt;
        },
          () => {
            this._logService.logObject("Token is not valid. Token:", token);

            jwt.isValid = false;
            return jwt;
          });

      } else {
        appClientId = environment.psfyIdpClientId;
        let decodedToken = this.decodeToken(token);

        var currentTime = Date.now() / 1000; // Convert to seconds
        var jwt = new CognitoJwt();
        jwt.isValid = decodedToken.exp > currentTime;

        return Promise.resolve(jwt);
      }


    }
    else if (product == "mc_pro") {
      appClientId = environment.cognitoAppIdMcPro
      var jwt = new CognitoJwt();
      jwt.isValid = false;

      return Promise.resolve(jwt);
    }
    else if (product == "psfy_events") {
      appClientId = environment.cognitoAppIdPsfyEvents
      var jwt = new CognitoJwt();
      jwt.isValid = false;

      return Promise.resolve(jwt);
    } else {
      var jwt = new CognitoJwt();
      jwt.isValid = false;

      return Promise.resolve(jwt);
    }


  }

  public validateStoredAccessToken = (): Promise<CognitoJwt> => {

    var userSession = this.getUserSession();
    var token = userSession.accessToken;
    var product = userSession.productId;
    var tenant = userSession.tenantId;
    var issuer = userSession.issuer;
    return this.validateAccessToken(product, tenant, token, issuer);


  }

  public storedAccessTokenIsValid = (): Promise<boolean> => {

    return this.validateStoredAccessToken().then((data: CognitoJwt) => {

      return data.isValid;

    });



  }




  public logout = (): void => {

    this._localStorageService.setObject(this._localStorageUserSessionItemName, new UserSession());

  }

  public setUserSession = (userSession: UserSession): void => {

    this._logService.logObject(userSession, "setting user session...")
    this._localStorageService.setObject(this._localStorageUserSessionItemName, userSession);
  }

  public setAdminUserSession = (userSession: UserSession): void => {

    this._logService.logObject(userSession, "setting user session...")
    userSession.adminUser = true;
    this._localStorageService.setObject(this._localStorageUserSessionItemName, userSession);
  }

  public getUserSession = (): UserSession => {
    var storedUserSession = this._localStorageService.getObject(this._localStorageUserSessionItemName);
    if (this._utilService.isNullOrUndefined(storedUserSession) == true) {
      storedUserSession = new UserSession();
    }
    return storedUserSession;
  }


  public decodeToken(token: string): any {

    const decoded = jwtDecode<any>(token); // Returns with the JwtPayload type

    return decoded;
  }

  public tokenIsExpired(token: string): boolean {
    let result = true;

    if (token) {
      // decode token
      //verify expired date
      var decoded = jwtDecode<JwtPayload>(token); // Returns with the JwtPayload type
      var expNum = decoded.exp * 1000;
      var now = Date.now();
      console.log(now);
      if (now < expNum) {
        result = false;
      }
    }

    return result;
  }

  public getSubjectFromToken(token: string): string {
    if (token) {
      // decode token
      //verify expired date
      const decoded = jwtDecode<JwtPayload>(token); // Returns with the JwtPayload type
      if (decoded?.sub) {
        return decoded.sub;
      } else {
        return '';
      }
    }

    return '';
  }




}
