export const environment = {
  production: false,
  apiDomain: "https://api-dev.psfyinsights.com", //'https://api-dev.psfyrab.net',
  apiBaseRoute: '/',
  idpBaseUrl:'https://psfy-insights-dev.auth.us-east-1.amazoncognito.com', //'https://dev.psfyidp.com',
  cognitoUserPoolId: 'us-east-1_vHMoqX9HJ',
  region: 'us-east-1',
  cognitoAppIdInsightsSpa: '572cofgqjdmd2ajb8mu0n96ji8',
  cognitoAppIdMcTrade: '5s93o4qe8bcnudpo2h4o5poh30',
  cognitoAppIdMcPro: '6s07a8jf61gj73g85f5rl4dk6n',
  cognitoAppIdPsfyEvents: 'm33nads3337p1bfutcjcaljf',
  psfyIdpClientId: 'insights_dev_public_client',
  psfyIdpClientSecret: 'f29ec8a3-44d0-ed85-7750-2662cf44e4d7',
  psfyIdpBaseUrl: 'https://dev-auth.psfyidp.com' //'https://dev.psfyidp.com'  //'https://localhost:44388' //
};
